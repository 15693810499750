<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row" v-if="detail">
                <div class="col-12">
                    <div class="custom-text-wrapper mb-4">
                        <div class="row">
                            <div class="col-12"><div class="custom-font-lg border-bottom mb-3">Data Dasar</div></div>
                            <div class="col-12 col-lg-3 mb-3">
                                <div class="custom-text-group">
                                    <label>Kode</label>
                                    <p>{{ detail.code }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="custom-text-group">
                                    <label>Nama</label>
                                    <p>{{ detail.name }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 mb-3">
                                <div class="custom-text-group">
                                    <label>Telepon</label>
                                    <p>{{ detail.phone }}</p>
                                </div>
                            </div>
                            <div class="col-12"><div class="custom-font-lg border-bottom my-3">Data Alamat</div></div>
                            <div class="col-12 col-lg-4 mb-3">
                                <div class="custom-text-group">
                                    <label>Provinsi</label>
                                    <p>{{ detail.province_name }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <div class="custom-text-group">
                                    <label>Kabupaten</label>
                                    <p>{{ detail.regency_name }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <div class="custom-text-group">
                                    <label>Kecamatan</label>
                                    <p>{{ detail.district_name }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Alamat</label>
                                    <p>{{ detail.address }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <google-maps-component :center="detail.lat && detail.long ? {lat: detail.lat, lng: detail.long} : {lat: -6.1754, lng: 106.8272}" :size="{width: '100%', height: '300px'}"/>
                            </div>
                            <div class="col-12"><div class="custom-font-lg border-bottom my-3">Data Tambahan</div></div>
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="custom-text-group">
                                    <label>Pengguna APM</label>
                                    <p>{{ detail.is_apm ? 'Ya' : 'Tidak' }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <div class="custom-text-group">
                                    <label>Pengguna SIMPUS</label>
                                    <p>{{ detail.is_simpus ? 'Ya' : 'Tidak' }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Informasi</label>
                                    <p class="custom-textarea-group">{{ detail.information }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label class="custom-font-sm custom-font-color-secondary">Logo</label>
                                <div class="custom-image-card p-1">
                                    <div class="custom-image-preview-wrapper justify-content-center">
                                        <div class="custom-image-preview-card m-2 shadow-sm" v-viewer="{toolbar: false, keyboard: false}">
                                            <img :src="detail.logo_url" alt="health-center-logo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label class="custom-font-sm custom-font-color-secondary">Gambar</label>
                                <div class="custom-image-card p-1">
                                    <div class="custom-image-preview-wrapper justify-content-center">
                                        <div class="custom-image-preview-card m-2 shadow-sm" v-viewer="{toolbar: false, keyboard: false}">
                                            <img :src="detail.image_url" alt="health-center-image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-lg-3">
                            <router-link :to="{name: 'health_center'}" class="btn custom-btn-outline-02 w-100" v-if="user.role_id == 1">Kembali</router-link>
                            <router-link :to="{name: 'health_center.edit', params: {id: id}}" class="btn custom-btn-02 w-100" v-if="user.role_id == 2">Ubah</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
</template>

<script>
    import ContentComponent from '@/components/external/ContentComponent'
    import GoogleMapsComponent from '@/components/external/GoogleMapsComponent'
    import { mapGetters } from 'vuex'

	export default {
        components: {
            'content-component': ContentComponent,
            'google-maps-component': GoogleMapsComponent,
        },
        data() {
            return {
                id: this.$route.params.id,
                detail: null,
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/getUser',
            }),
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                if(this.user.role_id != 1 && this.user.profile.health_center_id != this.id){
                    this.$router.push({name: 'error_404'})
                }
                else{
                    this.$store.dispatch('health_center/detailHealthCenter', {health_center_id: this.id}).then((response) => {
                        if(response.status){
                            this.detail = response.data[0]
                        }
                    })
                }
            },
		}
	}
</script>