<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3">
                    <div class="custom-badge">
                        <div class="custom-badge-label">
                            <i class="fas fa-user"></i>
                            Pasien
                        </div>
                        <div class="custom-badge-counter">{{ user_count.patient }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mb-3">
                    <div class="custom-badge">
                        <div class="custom-badge-label">
                            <i class="fas fa-user"></i>
                            Petugas
                        </div>
                        <div class="custom-badge-counter">{{ user_count.health_center }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mb-3">
                    <div class="custom-badge">
                        <div class="custom-badge-label">
                            <i class="fas fa-user"></i>
                            Penulis
                        </div>
                        <div class="custom-badge-counter">{{ user_count.writter }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                    <div class="custom-badge">
                        <div class="custom-badge-label">
                            <i class="fas fa-medkit"></i>
                            Total Puskesmas
                        </div>
                        <div class="custom-badge-counter">{{ health_center_count.health_center }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                    <div class="custom-badge">
                        <div class="custom-badge-label">
                            <i class="fas fa-medkit"></i>
                            Pengguna JQCare
                        </div>
                        <div class="custom-badge-counter">{{ health_center_count.health_center_client }}</div>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'

	export default {
        components: {
            'content-component': ContentComponent,
        },
        data() {
            return {
                user_count: {
                    patient: 0,
                    health_center: 0,
                    writter: 0,
                },
                health_center_count: {
                    health_center: 0,
                    health_center_client: 0,
                }
            }
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('user/countUser').then((response) => {
                    if(response.status){
                        this.user_count = response.data
                    }
                })
                this.$store.dispatch('health_center/countHealthCenter').then((response) => {
                    if(response.status){
                        this.health_center_count = response.data
                    }
                })
            },
        }
	}
</script>