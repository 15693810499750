<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row">
                <div class="col-12">
                    <div class="custom-text-wrapper mb-4">
                        <div class="row">
                            <div class="col-12"><div class="custom-font-lg border-bottom mb-3">Data Dasar</div></div>
                            <div class="col-12 col-lg-3 mb-3" :class="validation.firstError('form.code') ? 'custom-form-error' : ''">
                                <label class="form-label">Kode</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Kode" v-model="form.code">
                                <div class="custom-form-error-message">{{ validation.firstError('form.code') }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
                                <label class="form-label">Nama</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Nama" v-model="form.name">
                                <div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
                            </div>
                            <div class="col-12 col-lg-3 mb-3" :class="validation.firstError('form.phone') ? 'custom-form-error' : ''">
                                <label class="form-label">Telepon</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Telepon" v-model="form.phone">
                                <div class="custom-form-error-message">{{ validation.firstError('form.phone') }}</div>
                            </div>
                            <div class="col-12"><div class="custom-font-lg border-bottom my-3">Data Alamat</div></div>
                            <div class="col-12 col-lg-4 mb-3" :class="validation.firstError('form.province_id') ? 'custom-form-error' : ''">
                                <label class="form-label">Provinsi</label>
                                <v-select v-model="form.province_id" :options="province_options" placeholder="Provinsi" label="label" :reduce="province_options => province_options.id" class="custom-vselect"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.province_id') }}</div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3" :class="validation.firstError('form.regency_id') ? 'custom-form-error' : ''">
                                <label class="form-label">Kabupaten</label>
                                <v-select v-model="form.regency_id" :options="regency_options" placeholder="Kabupaten" label="label" :reduce="regency_options => regency_options.id" :disabled="regency_options.length == 0" class="custom-vselect"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.regency_id') }}</div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3" :class="validation.firstError('form.district_id') ? 'custom-form-error' : ''">
                                <label class="form-label">Kecamatan</label>
                                <v-select v-model="form.district_id" :options="district_options" placeholder="Kecamatan" label="label" :reduce="district_options => district_options.id" :disabled="district_options.length == 0" class="custom-vselect"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.district_id') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.address') ? 'custom-form-error' : ''">
                                <label class="form-label">Alamat</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Alamat" v-model="form.address">
                                <div class="custom-form-error-message">{{ validation.firstError('form.address') }}</div>
                            </div>
                            <div class="col-12 mb-3">
                                <google-maps-component :center="form.lat && form.long ? {lat: form.lat, lng: form.long} : {lat: -6.1754, lng: 106.8272}" :size="{width: '100%', height: '300px'}" :drag="true" @changed="set_location"/>
                            </div>
                            <div class="col-12"><div class="custom-font-lg border-bottom my-3">Data Tambahan</div></div>
                            <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('form.is_apm') ? 'custom-form-error' : ''" v-if="user.role_id == 1">
                                <label class="form-label">Pengguna APM</label>
                                <div class="d-flex align-items-center mt-2">
                                    <div class="custom-radio me-2">
                                        <input type="radio" id="apm_true" value="1" v-model="form.is_apm">
                                        <label for="apm_true">Iya</label>
                                    </div>
                                    <div class="custom-radio ms-2">
                                        <input type="radio" id="apm_false" value="0" v-model="form.is_apm">
                                        <label for="apm_false">Tidak</label>
                                    </div>
                                </div>
                                <div class="custom-form-error-message">{{ validation.firstError('form.is_apm') }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('form.is_simpus') ? 'custom-form-error' : ''" v-if="user.role_id == 1">
                                <label class="form-label">Pengguna SIMPUS</label>
                                <div class="d-flex align-items-center mt-2">
                                    <div class="custom-radio me-2">
                                        <input type="radio" id="simpus_true" value="1" v-model="form.is_simpus">
                                        <label for="simpus_true">Iya</label>
                                    </div>
                                    <div class="custom-radio ms-2">
                                        <input type="radio" id="simpus_false" value="0" v-model="form.is_simpus">
                                        <label for="simpus_false">Tidak</label>
                                    </div>
                                </div>
                                <div class="custom-form-error-message">{{ validation.firstError('form.is_simpus') }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="validation.firstError('form.information') ? 'custom-form-error' : ''">
                                <label class="form-label">Informasi</label>
                                <textarea for="description" class="form-control custom-form-control" rows="5" placeholder="Informasi" v-model="form.information"></textarea>
                                <div class="custom-form-error-message">{{ validation.firstError('form.information') }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('form.logo') ? 'custom-form-error' : ''">
                                <label>Logo</label>
                                <file-input
                                    :reference="'logo'"
                                    :multiple="true"
                                    :maximum="1"
                                    :max_size="1049600"
                                    :accept="'image/png, image/jpg, image/jpeg'"
                                    :extensions="['png', 'jpg', 'jpeg']"
                                    :resize="{width: 256, height: 256}"
                                    @set_queue="queue_files"
                                    :old_file="logo_file"
                                    :old_delete="true"
                                />
                                <div class="custom-form-error-message">{{ validation.firstError('form.logo') }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('form.image') ? 'custom-form-error' : ''">
                                <label>Gambar</label>
                                <file-input
                                    :reference="'image'"
                                    :multiple="true"
                                    :maximum="1"
                                    :max_size="2099200"
                                    :accept="'image/png, image/jpg, image/jpeg'"
                                    :extensions="['png', 'jpg', 'jpeg']"
                                    @set_queue="queue_files"
                                    :old_file="image_file"
                                    :old_delete="true"
                                />
                                <div class="custom-form-error-message">{{ validation.firstError('form.image') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-lg-3 order-lg-2">
                            <button type="button" class="btn custom-btn-02 w-100" @click="submit()">{{ id ? 'Simpan' : 'Tambah' }}</button>
                        </div>
                        <div class="col-12 col-lg-3 order-lg-1">
                            <router-link :to="{name: 'health_center'}" class="btn custom-btn-outline-02 w-100" v-if="user.role_id == 1">Kembali</router-link>
                            <router-link :to="{name: 'health_center.detail', params: {id: id}}" class="btn custom-btn-outline-02 w-100" v-if="user.role_id == 2">Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
</template>

<script>
    import ContentComponent from '@/components/external/ContentComponent'
    import GoogleMapsComponent from '@/components/external/GoogleMapsComponent.vue'
    import FileInput from '@/components/external/input/FileInput'
    import vSelect from 'vue-select'
    import { Validator } from 'simple-vue3-validator'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'google-maps-component': GoogleMapsComponent,
            'file-input': FileInput,
            'v-select': vSelect,
        },
        data() {
            return {
                id: this.$route.params.id,
                logo_file: [],
                image_file: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/getUser',
                form: 'health_center/getHealthCenterForm',
                province_options: 'master_data/getProvinceOptions',
                regency_options: 'master_data/getRegencyOptions',
                district_options: 'master_data/getDistrictOptions',
            }),
        },
        watch: {
            'form.province_id': function (newData) {
                if(newData){
                    this.$store.dispatch('master_data/getRegencyOptions', {province_id: this.form.province_id})
                }
                else{
                    this.form.regency_id = null
                    this.form.district_id = null
                    this.$store.dispatch('master_data/resetRegencyOptions')
                    this.$store.dispatch('master_data/resetDistrictOptions')
                }
            },
            'form.regency_id': function (newData) {
                if(newData){
                    this.$store.dispatch('master_data/getDistrictOptions', {regency_id: this.form.regency_id})
                }
                else{
                    this.form.district_id = null
                    this.$store.dispatch('master_data/resetDistrictOptions')
                }
            }
        },
        validators: {
            'form.code': function(value){
                return Validator.value(value).required('Wajib diisi.').maxLength(15)
            },
            'form.address': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.phone': function(value){
                return Validator.value(value).integer('Hanya angka.').minLength(10).maxLength(12)
            },
            'form.province_id': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.regency_id': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.district_id': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.is_apm': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.is_simpus': function(value){
                return Validator.value(value).required('Wajib dipilih.')
            },
            'form.information': function(value){
                return Validator.value(value).required('Wajib diisi.')
            },
            'form.logo': function(value){
                return Validator.custom(function () {
                    if (!Validator.isEmpty(value)) {
                        for(var i = 0; i < value.length; i++) {
                            if (value[i].error != '') {
                                return 'Invalid file format.'
                            }
                        }
                    }
                })
            },
            'form.image': function(value){
                return Validator.custom(function () {
                    if (!Validator.isEmpty(value)) {
                        for(var i = 0; i < value.length; i++) {
                            if (value[i].error != '') {
                                return 'Invalid file format.'
                            }
                        }
                    }
                })
            },
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                if(this.user.role_id != 1 && this.user.profile.health_center_id != this.id){
                    this.$router.push({name: 'error_404'})
                }
                else{
                    this.$store.dispatch('health_center/resetHealthCenterForm')
                    this.$store.dispatch('master_data/getProvinceOptions')

                    if(this.id){
                        this.$store.dispatch('health_center/detailHealthCenter', {health_center_id: this.id}).then((response) => {
                            if(response.status){
                                this.form.code = response.data[0].code
                                this.form.name = response.data[0].name
                                this.form.address = response.data[0].address
                                this.form.phone = response.data[0].phone
                                this.form.lat = response.data[0].lat
                                this.form.long = response.data[0].long
                                this.form.information = response.data[0].information
                                this.form.is_apm = response.data[0].is_apm
                                this.form.is_simpus = response.data[0].is_simpus

                                if(!response.data[0].province_id && response.data[0].regency_id){
                                    this.$store.dispatch('master_data/getRegency', {id: response.data[0].regency_id}).then((result) => {
                                        if(result.status){
                                            this.form.province_id = parseInt(result.data[0].province_id)
                                            this.form.regency_id = parseInt(response.data[0].regency_id)
                                        }
                                    })
                                }
                                else{
                                    this.form.province_id = parseInt(response.data[0].province_id)
                                    this.form.regency_id = parseInt(response.data[0].regency_id)
                                    this.form.district_id = parseInt(response.data[0].district_id)
                                }

                                if(response.data.logo){
                                    this.logo_file.push({
                                        error: '',
                                        file_status: this.$root.copy(response.data[0].logo),
                                        file_name: this.$root.copy(response.data[0].logo),
                                        file_url: this.$root.copy(response.data[0].logo_url),
                                    })
                                }

                                if(response.data.image){
                                    this.image_file.push({
                                        error: '',
                                        file_status: this.$root.copy(response.data[0].image),
                                        file_name: this.$root.copy(response.data[0].image),
                                        file_url: this.$root.copy(response.data[0].image_url),
                                    })
                                }
                            }
                        })
                    }
                }
            },
            set_location(value){
                this.form.lat = value.lat
                this.form.long = value.lng
            },
            queue_files(data){
                if(data.files.length > 0){
                    let files = []
                    for (let i = 0; i < data.files.length; ++i) {
                        if(data.files[i].file){
                            files.push({
                                error: data.files[i].error,
                                file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
                            })
                        }
                        else{
                            files.push({
                                error: data.files[i].error,
                                file_status: data.files[i].file_status,
                                file_name: data.files[i].file_name,
                                file_url: data.files[i].file_url
                            })
                        }
                    }
                    this.form[data.ref] = files
                }
                else{
                    this.form[data.ref] = null
                }
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        let formData = new FormData();
                        formData.append('code', parent.form.code)
                        formData.append('name', parent.form.name)
                        formData.append('phone', parent.form.phone)
                        formData.append('province_id', parent.form.province_id)
                        formData.append('regency_id', parent.form.regency_id)
                        formData.append('district_id', parent.form.district_id)
                        formData.append('address', parent.form.address)
                        formData.append('lat', parent.form.lat)
                        formData.append('long', parent.form.long)
                        formData.append('is_apm', parent.form.is_apm)
                        formData.append('is_simpus', parent.form.is_simpus)
                        formData.append('information', parent.form.information)
                        
                        if(parent.form.logo){
                            for(let i= 0; i < parent.form.logo.length; i++) {
                                if(parent.form.logo[i].file){
                                    formData.append("logo", parent.form.logo[i].file, parent.form.logo[i].file.name)
                                }
                                else{
                                    formData.append('logo', parent.form.logo[i].file_status)
                                }
                            }
                        }

                        if(parent.form.image){
                            for(let i= 0; i < parent.form.image.length; i++) {
                                if(parent.form.image[i].file){
                                    formData.append("image", parent.form.image[i].file, parent.form.image[i].file.name)
                                }
                                else{
                                    formData.append('image', parent.form.image[i].file_status)
                                }
                            }
                        }
                        if(parent.id){
                            let data = {
                                id: parent.id,
                                data: formData
                            }
                            parent.$store.dispatch('health_center/updateHealthCenter', data).then((response) => {
                                if(response.status){
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                    parent.$router.push({name: 'health_center.detail', params: {id: parent.id}})
                                }
                            })
                        }
                        else{
                            parent.$store.dispatch('health_center/createHealthCenter', formData).then((response) => {
                                if(response.status){
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                    parent.$router.push({name: 'health_center'})
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
		}
	}
</script>