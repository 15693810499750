<template>
    <content-component>
        <template v-slot:table_create_button>
            <button class="btn custom-btn-02 w-100" @click="open_category_modal()">
                Kategori Artikel
            </button>
        </template>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_button>
                    <div class="col-12 col-lg-3">
                        <router-link :to="{name: 'article.create'}" class="btn custom-btn-02 w-100">
                            <i class="fas fa-plus"></i>
                            Artikel
                        </router-link>
                    </div>
                </template>
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ datetime_format(data.date) }}</td>
                        <td>{{ data.title }}</td>
                        <td>{{ data.category_name }}</td>
                        <td>{{ data.user }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <router-link :to="{name: 'article.detail', params: {id: data.id}}" class="btn custom-btn-unborder-02 py-0">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <router-link :to="{name: 'article.edit', params: {id: data.id}}" class="btn custom-btn-unborder-02 py-0">
                                    <i class="fas fa-pen"></i>
                                </router-link>
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="delete_data(data.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
    </content-component>
    <article-category-modal ref="articleCategoryModal"/>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import ArticleCategoryModal from './ArticleCategoryModal'
    import Swal from 'sweetalert2'
    import moment from 'moment'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
            'article-category-modal': ArticleCategoryModal,
        },
        data() {
            return {
                table_header: [
                    { name: 'date', title: 'Tanggal', width: '18%', sort: true },
                    { name: 'title', title: 'Judul', width: '35%', sort: true },
                    { name: 'category', title: 'Kategori', width: '20%', sort: true },
                    { name: 'author', title: 'Penulis', width: '17%', sort: true },
                    { name: '', title: 'Actions', width: '10%', sort: false, align:'text-center' },
                ],
                table_attributes: {
                    sort_by: 'date',
                    sort_type: 'desc',
                    search: null,
                    page: 1,
                    paginate: 30,
                },
                table: null,
                table_data: [],
            }
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('article/getArticle', value).then((response) => {
                    this.table = response.data
                    if(value.page == 1){
                        this.table_data = this.table.data
                    }
                    else{
                        this.table_data = [ ...this.table_data, ...this.table.data]
                    }
                    this.table_attributes = value
                })
            },
            datetime_format(value){
                return moment(value).format("DD-MM-YYYY / HH:mm")
            },
            open_category_modal(){
                this.$refs.articleCategoryModal.open_modal()
            },
            close_modal(value){
                if(value){
                    this.init()
                }
            },
            delete_data(value){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('article/deleteArticle', value).then(() => {
                            parent.table_attributes.page = 1
                            parent.get_table(parent.table_attributes)
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil menghapus data',
                                confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                confirmButtonText: parent.$root.swal('doneButtonText'),
                            })
                        })
                    }
                })
            },
        }
	}
</script>